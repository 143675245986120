import axios from 'axios'
import { URL } from "../utils/URL";

export default function Test() {

 
   
  return(
    <h1>hsit</h1>
  )
}